import React, { useContext } from 'react'
import {PageContext} from '../../context/PageContextProvider';
import logo from '../../img/rowingMavensLogo.svg';
import Menu from '../Menu/Menu';
import './Header.css';

interface Props {}

function Header(props: Props) {
  const {setCurrentPage} = useContext(PageContext);

  return (
    <div className="Header">
      <img src={logo} className="logo" alt="logo" onClick={()=>setCurrentPage('home')}/>  
      <div className="spacer"/>
      <Menu/>
    </div>
  )
}

export default Header
