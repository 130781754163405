import React, { useContext, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faCaretDown } from '@fortawesome/free-solid-svg-icons';

import {PageContext} from '../../context/PageContextProvider';
import './Menu.css';



interface Props {}

function decorate(pageName: string, currentPage: string){
  if(currentPage === pageName){
    return 'currentPage';
  } else {
    return '';
  }
}

let pages = [
  {name:'home', displayName:'Home'},
  {name:'timing', displayName:'Timing'},
  {name:'about', displayName:'About'},
  {name:'contact', displayName:'Contact'},
];

function Menu(props: Props) {
  // const {} = props

  const {currentPage, setCurrentPage} = useContext(PageContext);
  const [showVerticalMenu, setShowVerticalMenu] = useState(false);

  const pageButtons = pages.map((page) => {
    return (
      <div className={'pageName ' + decorate(page.name, currentPage)} 
        key={`pageName${page.name}`}
      onClick={()=>{
        setShowVerticalMenu(false);
        setCurrentPage(page.name);
        window.scrollTo(0, 0)
    }}
    >{page.displayName}</div>);
  })

  // <div className="pageName" onClick={()=>setCurrentPage('timing')}>Timing</div>
  // <div className="pageName" onClick={()=>setCurrentPage('timing')}>Regatta Management</div>
  // <div className="pageName" onClick={()=>setCurrentPage('timing')}>Partners</div> 
  // <div className="pageName" onClick={()=>setCurrentPage('about')}>About</div>
  // <div className="pageName" onClick={()=>setCurrentPage('contact')}>Contact</div>


  return (
    <div className="Menu">
      <button className="menuToggleButton" onClick={()=>setShowVerticalMenu(!showVerticalMenu)}><FontAwesomeIcon icon={faBars} /></button>   

      <div className="horizontalPageNames">
        {pageButtons}
      </div>
      {showVerticalMenu && <div className="verticalPageNames">
        <div className="verticalCaretDownContainer"><FontAwesomeIcon icon={faCaretDown} className="verticalCaretDown" /></div>
        {pageButtons}
      </div>}
    </div>

  )
}

export default Menu
