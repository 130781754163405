import React, {useState, createContext} from 'react'

interface ContextAttributes{
  currentPage: string,
  setCurrentPage: React.Dispatch<React.SetStateAction<string>>
}

interface Props {
  children?: React.ReactNode;
}

export const PageContext = createContext<ContextAttributes>({currentPage: '', setCurrentPage: () => {}})

function PageContextProvider(props: Props) {
  const {children} = props

  const [currentPage, setCurrentPage] = useState('home');
  const context = {currentPage, setCurrentPage};

  return (
    <PageContext.Provider value={context}>
      {children}
    </PageContext.Provider>
  )
}

export default PageContextProvider
