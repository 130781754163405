import React, { useEffect, useState, useContext } from 'react'
import clock from '../../img/clock.jpg';
import mgmt from '../../img/henley.jpg';
import backgroundImage0 from '../../img/backgroundImage0.jpg';
import backgroundImage1 from '../../img/backgroundImage1.jpg';
import backgroundImage2 from '../../img/backgroundImage2.jpg';
import backgroundImage3 from '../../img/backgroundImage3.jpg';
import backgroundImage4 from '../../img/backgroundImage4.jpg';
import './Home.css';
import { PageContext } from '../../context/PageContextProvider';

interface Props {}

let images = [backgroundImage0, backgroundImage1, backgroundImage2, backgroundImage3, backgroundImage4];

let timer: NodeJS.Timer;

function Home(props: Props) {
  // const {} = props

  const {setCurrentPage} = useContext(PageContext);

  const [counter, setCounter] = useState(0);
  
  useEffect(()=>{
    //console.log(`setting interval to ${counter}`);
    timer = setInterval(() => {
      setCounter(counter+1);
      //console.log(`setting counter to ${counter}`);
    }, 5000)

    // Need to set up something to clear this timer, or else a new one is created 
    // every single time the page is updated and the number of interval handlers grows
    // with every second until the page collapses (you'll see that if you put log statements in
    // the useEffect handler function and the interval handler function). 
    // By setting up this handler to clear the timer, it should work better when a page is replaced 
    // in the hot reload environment of "npm start" 
    return () => {
      //console.log("clearing interval");
      clearInterval(timer);
    }
  }, [counter]);

  const imageElements = images.map( (image, index) => {
    return <img src={image} key={'timingBackgroundImage'+index} className={'backgroundImage homeBackgroundImage ' + (counter%images.length !== index ? 'transparent':'')} alt='background'/>;
  });

  return (
    <div className="Home">
      {imageElements}
      <div className="expertise">
        <div className="infoCard title">Our Expertise</div>
        <div className="expertiseItems">
          <div className="infoCard expertiseItem" onClick={()=>setCurrentPage('timing')}>
            <img src={clock} alt='timing'/>
            <div className="itemTitle">Timing</div>
            <div className="explanation">Race timing using a combination of special timing hardware and mobile applications</div>
          </div>
          <div className="dynamicSpacer"/>
          <div className="infoCard expertiseItem">
            <img src={mgmt} alt='mgmt'/>
            <div className="itemTitle">Regatta Management</div>
            <div className="explanation">Several levels of support: training, marketing, or acting as regatta director </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home
