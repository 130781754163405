import React from 'react'
import './TimingInfoCard.css';

interface Props {
  image?: string;
  children?: React.ReactNode;
  reverse?: boolean;
  style?: object
}

function TimingInfoCard(props: Props) {
  const {image, children, reverse, style} = props

  const imageSection = (
    <div className="timingImageContainer">
      <img src={image} alt='timing'/>
    </div>
  );

  const descriptionSection = <div className="description">{children}</div>;
  
  const hasTwoSections = image && children;

  let leftSection, rightSection;

  if(reverse || !hasTwoSections){
    leftSection = descriptionSection;
    rightSection = imageSection;
  } else {
    leftSection = imageSection;
    rightSection = descriptionSection;
  }

  return (
    <div className="infoCard TimingInfoCard" style={style}>
      {leftSection}
      {hasTwoSections ? rightSection :null}
    </div>
)
}

export default TimingInfoCard
