import React from 'react'
import ribbonImage from '../../img/ribbonBackground.png';
import './About.css';

interface Props {}

function History(props: Props) {
  // const {} = props

  return (
    <div className="About">
      <img src={ribbonImage} className="backgroundImage" alt="background"/>

      <div className="infoCard aboutInfoCard">
        <h1>About Rowing Mavens</h1>

        <p>Rowing Mavens was spun off from HereNow Systems in 2021 to 
        concentrate on facilitating ways for staff to contribute to the 
        sport. HereNow continues to expand as a technology platform and now 
        Rowing Mavens takes on more work related to regatta management and other 
        aspects of the sport. </p>

        <p><a href="https://www.gladwellbooks.com/landing-page/about-malcolm-gladwell/">Malcolm Gladwell</a> spoke about "mavens" in his seminal book called <a href="https://a.co/d/04vHbqx">The Tipping Point</a>. 
        Mavens are "information specialists," or "people we rely upon to connect us with new information." 
        Mavens start "word-of-mouth epidemics due to their knowledge, social skills, and ability to communicate."</p>

        <p>When we looked at how people contributed to the success of the <a href="https://herenow.com">HereNow Racing Platform</a>, it was clear 
        that the staff played the role of mavens in the way that technology has improved race operations over the last decade.
        We just didn't want to stop there.</p>

        <p>Everyone at the company is involved in rowing in some way. Rowers, timers, regatta directors, 
          coaches, referees, and crew moms &amp; dads. We're all rowing junkies and love it. It's a way of life and we wouldn't 
          want it any other way.</p>

        <p>As we expand into new things, we look forward to meeting you and contributing the sport.</p>
      
        <p><span className="quote">Tendit in Ardua Virtus</span><br/><span className="translation">Virtue strives for what is difficult</span><br/><span className="attribution">- Ovid</span></p>
      </div>
    </div>
  )
}

export default History
