import React, { useContext } from 'react';
import './App.css';
import Header from '../Header/Header';
import Home from '../Home/Home';
import {PageContext} from '../../context/PageContextProvider';
import Contact from '../Contact/Contact';
import Timing from '../Timing/Timing';
import About from '../About/About';

function App() {

  const {currentPage} = useContext(PageContext);

  let page;
  switch(currentPage){
    case 'contact':
      page = <Contact/>;
      break;
    case 'timing':
      page = <Timing/>;
      break;
    case 'about':
      page = <About/>;
      break;
    case 'home':
    default:
      page = <Home/>;
      break;
  }

  return (
    <div className="App">
      <Header/>
      {page}
    </div>
  );
}

export default App;
