import React from 'react'
import './Contact.css';
import ribbonImage from '../../img/ribbonBackground.png';

interface Props {}

function Contact(props: Props) {
  // const {} = props

  return (
    <div className="Contact">
      <img src={ribbonImage} className="backgroundImage" alt="background"/>

      <div className="contactInfoCards">

        <div className="headquarters">
          <h2>Headquarters</h2>

          <div className="headquartersInfo">
            <div className="address">
              <h3>Address</h3>
              Rowing Mavens LLC<br/>
              1 Broadway<br/>
              14th Floor<br/>
              Cambridge, MA 02142
            </div>
            <iframe 
              title="headquartersMap"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2948.0429618291946!2d-71.08580464857233!3d42.3629236790846!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e370a5b761c351%3A0x3ab9187e6e6ffeb9!2s1%20Broadway%2C%20Cambridge%2C%20MA%2002142!5e0!3m2!1sen!2sus!4v1670539385640!5m2!1sen!2sus" 
              width={300} 
              height={300} 
              style={{'border':0}} 
              className="headquartersMap"
              allowFullScreen={false}
              loading="lazy" 
              referrerPolicy="no-referrer-when-downgrade"></iframe>
          </div>
        </div>


        <div className="emailAddresses">

          <h2>Email Addresses</h2>

          <table className="emailAddressTable">
            <tbody>
            <tr>
                <td>New Clients</td>
                <td><a href="mailto:sales@rowingmavens.com">sales@rowingmavens.com</a></td>
              </tr>
              <tr>
                <td>Existing Clients</td>
                <td><a href="mailto:support@rowingmavens.com">support@rowingmavens.com</a></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

  )
}

export default Contact
