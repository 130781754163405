import React from 'react'
import timingImage from '../../img/clock.jpg';
import herenowLogo from '../../img/herenow.jpg';
import finishlynxCamera from '../../img/finishlynx.jpg';
import appleIpadLogo from '../../img/appleIpad.svg';
import './Timing.css';
import TimingInfoCard from '../TimingInfoCard/TimingInfoCard';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

interface Props {}

function Timing(props: Props) {
  // const {} = props

  return (
    <div>
      <img src={timingImage} className="backgroundImage" alt="background"/>
      
      <div className="timingInfoCards">

        <TimingInfoCard>
          <h1>Timing</h1>
          <p>Rowing Mavens transform regattas by working with the regatta director to implement technology that coordinate operations and ensure fast reliable results.</p>

          <p>Mavens know deeply key technologies and love to work with the regatta personnel to train people in those technologies and to run efficient operations. The key technologies that Rowing Mavens help deploy are: 
            <ul>
              <li>The HereNow Racing Platform</li>
              <li>Finishlynx photofinish cameras</li>
              <li>Apple iPads</li>
            </ul>
          </p>
          <p>
            Mavens work with regatta staff, volunteers, and race officials to help them understand how to use technology for each person's role in the regatta. The key locations that require technology are: 
            <ul>
              <li>Registration</li>
              <li>Weigh-Ins</li>
              <li>Launch Docks</li>
              <li>Starting Line</li>
              <li>Finish Line</li>
              <li>Results and Awards</li>
            </ul>
          </p>
          <p>Rowing Mavens can help regattas of all sizes. Large regattas benefit from multi-camera timing precision, large iPad deployments, points trophies, results displays, and coordination with live streaming video. Smaller regattas benefit from investing in training to implement firm bedrock for their operations and more inexpensive mobile app based timing that gets them to smoothly grow into bigger shoes. There is a range of requirements between large and small regattas that fits everyone's needs.</p>
          
          <p>We are happy to work ourselves out of a job by training local personnel, but some regattas find that it is to their advantage to have staff on site. We even have remote support plans for regattas that have invested in training and are now comfortable with doing their own operations on site. We are flexible in how we work with all types of clients.</p> 
        </TimingInfoCard>

        <TimingInfoCard image={herenowLogo}>
          <b>HereNow Racing Platform</b> encompasses timing, referee operations, boat telemetry, registration, 
          results distribution, and video streaming. Accurate results start at registration and require the 
          coordination with weigh-ins, launch docks, and the starting line, so we help deploy and train HereNow 
          mobile application technology to race officials and regatta staff. HereNow makes your regatta run smoothly 
          and efficiently.<br />
          <a href="https://herenow.com">More Info <FontAwesomeIcon icon={faArrowRight} /></a> 
        </TimingInfoCard>

        <TimingInfoCard image={finishlynxCamera} reverse={true}>
          <b>Finishlynx photofinish cameras</b> offer high precision, images to settle disputes, and the ability to handle 
          high volume race timing for large races. Finishlynx cameras provide millisecond level timing data 
          required for international and championship races especially when combined with streaming video. 
          For a close race, photofinish pictures settle all debates. Camera systems can better keeup up with 
          high volume time trials and large fall head races. Photofinish systems 
          are essential for high end racing.<br />
          <a href="https://finishlynx.com">More Info <FontAwesomeIcon icon={faArrowRight} /></a>
        </TimingInfoCard>

        <TimingInfoCard image={appleIpadLogo}>
          We use <b>Apple iPads</b> with cellular data plans, waterproof cases, and extra 
          batteries to deploy technology around a racing venue. With cellular data plans, 
          people have flexibility to move around as they see fit without having to set up wifi access points
          all around your venue. Waterproof cases ensure that your equipment doesn't get damaged in the rain 
          or in a launch with splashing water. Extra batteries get you through long days of racing.<br />
          <a href="https://apple.com">More Info <FontAwesomeIcon icon={faArrowRight} /></a>
        </TimingInfoCard>

        <TimingInfoCard style={{marginBottom: 25}}>
          <p>If you would like to find out more about what Rowing Mavens can do for your regatta, contact us at <a href="sales@rowingmavens.com">sales@rowingmavens.com</a></p>
        </TimingInfoCard>
      </div>
    </div>
  )
}

export default Timing
